import { Modal, Row, Col, message, Tabs, Upload, Select } from 'antd';
import './CreateCategory.scss';
import { useState, useEffect, useCallback } from 'react';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { BsTrash } from 'react-icons/bs';
import Pl from '../../../../components/Pl/Pl';
import { useSelector } from 'react-redux';
import catService from '../../../../services/catService';
import orgService from '../../../../services/orgService';
import SaveIcon from '../../../../icons/SaveIcon/SaveIcon';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import switchCrm from '../../../../funcs/switchCrm';
import SelectOrg from '../../../../components/SelectOrg/SelectOrg';
import EditHr from '../../../../components/EditHr/EditHr';

//week timetable
import weektimes from './weektimes';
import TimeSelect from '../../../orgs/orgsCreate/components/timeSelect/TimeSelect';
import { MdContentCopy } from 'react-icons/md';
import { checkIsBao } from '../../../../utils/checkIsBao';
import PlUpload from '../../../../components/PlUpload/PlUpload';
import checkDom from '../../../../funcs/checkDom';

const os = new orgService();
const cs = new catService();

const CreateCategory = ({ visible, close, updateList, editItem, setSelectedCat }) => {
    const { token, settings, settingsFront } = useSelector((state) => state);
    const [PictureUrl, setPictureUrl] = useState('');
    const [Image, setImage] = useState({});
    const [tempImage, setTempImage] = useState('');
    const [Name, setName] = useState('');
    const [NameKz, setNameKz] = useState('');
    const [NameEn, setNameEn] = useState('');

    const asyncRequest = async ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const handleUploadPictureUrl = async (e) => {
        setImage(e.file.originFileObj);
        const blobImg = URL.createObjectURL(e.file.originFileObj);
        setTempImage(blobImg);
    };

    const nameTabs = [
        {
            key: '1',
            label: 'Русский язык',
            children: (
                <Input
                    maskType={String}
                    shadow={true}
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={'Название категории'}
                />
            ),
        },
        {
            key: '2',
            label: 'Казахский язык',
            children: (
                <Input
                    maskType={String}
                    shadow={true}
                    value={NameKz}
                    onChange={(e) => setNameKz(e.target.value)}
                    placeholder={'Название категории на казахском'}
                />
            ),
        },
        {
            key: '3',
            label: 'Английский язык',
            children: (
                <Input
                    maskType={String}
                    shadow={true}
                    value={NameEn}
                    onChange={(e) => setNameEn(e.target.value)}
                    placeholder={'Название категории на английском языке'}
                />
            ),
        },
    ];

    const [ID, setID] = useState('');
    const [IIkoID, setIIkoID] = useState('');
    const [freePlate, setFreePlate] = useState('');
    const [freePlateId, setFreePlateId] = useState('');
    const [load, setLoad] = useState(false);
    const [delLoad, setDelLoad] = useState(false);
    const [orgs, setOrgs] = useState([]);
    const [orgsList, setOrgsList] = useState([]);
    const [CanOverwriteByIIko, setCanOverwriteByIIko] = useState('0');
    const [ItemOrder, setItemOrder] = useState(0);
    const [AllowedDeliveryTypes, setAllowedDeliveryTypes] = useState(2);
    const [isHideInOrg, setIsHideInOrg] = useState(false);
    const [HideInApp, setHideInApp] = useState('0');
    const [isAddedOnGl, setAddedOnGl] = useState(null);
    const [localIsAddedOnGl, setLocalIsAddedOnGl] = useState(null);

    const [hideOrgModal, setHideOrgModal] = useState(false);

    const [editChildTimeTable, setEditChildTimeTable] = useState(false);
    const [weekTimes, setWeekTimes] = useState(weektimes);
    const [timeTableLoad, setTimeTableLoad] = useState(false);
    const [categoryProducts, setCategoryProducts] = useState([]);

    const [cloneLoad, setCloneLoad] = useState(false);

    const openHideOrgModal = () => setHideOrgModal(true);
    const closeHideOrgModal = () => setHideOrgModal(false);

    const handleClose = () => {
        setSelectedCat(null);
        setPictureUrl('');
        setTempImage('');
        setImage();
        setName('');
        setNameKz('');
        setNameEn('');

        setIsHideInOrg(false);
        setAddedOnGl(0);
        setOrgsList([]);
        setIIkoID('');
        setID('');
        setHideInApp('0');
        close();
    };

    useEffect(() => {
        if (token) {
            os.getOrgs(token).then((res) => {
                setOrgs([
                    { value: 'Все', ID: 'All' },
                    ...res?.map((item) => {
                        return {
                            value: item.Name,
                            ID: item.ID,
                        };
                    }),
                ]);
            });
        }
    }, [token]);

    useEffect(() => {
        if (editItem && orgs?.length > 0 && visible) {
            os.getFreePlates(token, { CategoryID: editItem?.ID }).then((res) => {
                if (res.error) {
                    return;
                } else {
                    const obj = res?.map((obj) => ({
                        value: obj.ID,
                        label: obj.Name || obj.Name_en || obj.Name_kz,
                    }));
                    obj.unshift({ value: null, label: 'Нет' });
                    console.log(obj);
                    setCategoryProducts(obj);
                }
            });
            setHideInApp(editItem?.HideInApp);
            setName(editItem.PictureUrl);
            setName(editItem.Name);
            setNameKz(editItem?.Name_kz);
            setNameEn(editItem?.Name_en);
            setPictureUrl(editItem?.PictureUrl);
            setTempImage(editItem?.PictureUrl ?? '');
            setFreePlate(editItem?.freePlates);
            setFreePlateId(editItem?.freePlatesId);
            if (editItem?.Gl_type === '0') {
                setLocalIsAddedOnGl(true);
                setAddedOnGl('1');
            } else if (editItem?.Gl_type === null) {
                setLocalIsAddedOnGl(false);
                setAddedOnGl('0');
            }
            // setAddedOnGl(editItem?.Gl_type);

            setIIkoID(editItem.IIkoID);
            setIsHideInOrg(
                editItem.HiddenInOrganisations && editItem.HiddenInOrganisations != '/'
                    ? true
                    : false
            );
            if (editItem.HiddenInOrganisations && editItem.HiddenInOrganisations != '/') {
                let array = editItem.HiddenInOrganisations.split('//');
                setOrgsList(
                    array.map((item, index) => {
                        if (index == 0) {
                            return {
                                ID: item.replace(/\//g, ''),
                                value: orgs.find((i) => i.ID == item.replace(/\//g, ''))
                                    ?.value,
                            };
                        }
                        if (index == array.length - 1) {
                            return {
                                ID: item.replace(/\//g, ''),
                                value: orgs.find((i) => i.ID == item.replace(/\//g, ''))
                                    ?.value,
                            };
                        }
                        return {
                            ID: item,
                            value: orgs.find((i) => i.ID == item)?.value,
                        };
                    })
                );
            } else {
                setOrgsList([]);
            }
            setCanOverwriteByIIko(editItem.CanOverwriteByIIko);
            setItemOrder(editItem.ItemOrder);
            setAllowedDeliveryTypes(editItem.AllowedDeliveryTypes);
            setID(editItem.ID);
        }
    }, [editItem, orgs, visible]);

    const addOrg = () => {
        setOrgsList((state) => [...state, orgs[0]]);
    };

    const delOrg = (index) => {
        const pr = orgsList;
        const m = pr.splice(index, 1);
        setOrgsList([...pr]);
    };

    const selectOrg = (value, index, ID) => {
        let ur = orgsList;
        let p = ur.splice(index, 1, { value: value, ID });
        setOrgsList([...ur]);
    };

    const handleFreePlateSelect = (value) => {
        setFreePlateId(value);
    };

    const switchHiddenOrg = (e) => {
        setIsHideInOrg(e.target.checked);
        if (!e.target.checked) {
            setOrgsList([]);
        } else {
            setOrgsList([orgs[0]]);
        }
    };

    const createCat = () => {
        setLoad(true);
        const data = {
            // OrganisationID: '',
            IIkoID,
            CanOverwriteByIIko,
            PictureUrl,
            Name,
            Name_kz: NameKz,
            Name_en: NameEn,
            HiddenInOrganisations:
                orgsList.length > 0
                    ? orgsList
                          .filter((i) => i?.ID !== 'All')
                          .map((item) => `/${item.ID}`)
                          .join('/') + '/'
                    : '',
            AllowedDeliveryTypes,
            HideInApp,
            isAddedOnGl,
        };

        const formData = new FormData();

        formData.append('IIkoID', IIkoID);
        formData.append('CanOverwriteByIIko', CanOverwriteByIIko);
        formData.append('image', Image ? Image : PictureUrl);
        console.log('image1', Image ? Image : PictureUrl);
        formData.append('Name', Name);
        formData.append('Name_kz', NameKz);
        formData.append('Name_en', NameEn);
        formData.append('freePlates', freePlate);
        formData.append(
            'HiddenInOrganisations',
            orgsList?.length > 0
                ? orgsList
                      .filter((i) => i?.ID !== 'All')
                      .map((item) => `/${item.ID}`)
                      .join('/') + '/'
                : ''
        );
        formData.append('AllowedDeliveryTypes', AllowedDeliveryTypes);
        console.log(('AllowedDeliveryTypes', AllowedDeliveryTypes));
        formData.append('HideInApp', HideInApp);
        formData.append('Gl_type', isAddedOnGl);

        cs.addCat(token, formData)
            .then((res) => {
                window.location.reload();
                // dispatch(catalogUpdate(res))
                updateList(res);
            })
            .finally((_) => {
                setLoad(false);
                handleClose();
            });
    };

    const deleteCat = () => {
        setDelLoad(true);
        cs.delCat(token, { ID })
            .then((res) => {
                // window.location.reload();
                updateList(res);
                // dispatch(catalogUpdate(res))
            })
            .finally((_) => {
                setDelLoad(false);
                handleClose();
            });
    };

    const editCat = () => {
        setLoad(true);
        const data = {
            ID,
            IIkoID,
            CanOverwriteByIIko,
            // ItemOrder,
            PictureUrl: PictureUrl ?? '',
            freePlates: freePlate,
            freePlatesId: freePlateId,
            Name,
            Name_kz: NameKz,
            Name_en: NameEn,
            HiddenInOrganisations:
                orgsList?.length > 0
                    ? orgsList
                          .filter((i) => i?.ID !== 'All')
                          .map((item) => `/${item.ID}`)
                          .join('/') + '/'
                    : '',
            AllowedDeliveryTypes,
            HideInApp,
            isAddedOnGl,
        };

        const formData = new FormData();

        formData.append('ID', ID);
        formData.append('IIkoID', IIkoID);
        formData.append('CanOverwriteByIIko', CanOverwriteByIIko);
        formData.append('image', Image ? Image : PictureUrl);
        console.log('image2', Image ? Image : PictureUrl);
        formData.append('Name', Name);
        formData.append('Name_kz', NameKz);
        formData.append('Name_en', NameEn);
        formData.append('freePlates', freePlate);
        formData.append('freePlatesId', freePlateId);
        formData.append(
            'HiddenInOrganisations',
            orgsList?.length > 0
                ? orgsList
                      .filter((i) => i?.ID !== 'All')
                      .map((item) => `/${item.ID}`)
                      .join('/') + '/'
                : ''
        );
        formData.append('AllowedDeliveryTypes', AllowedDeliveryTypes);
        formData.append('HideInApp', HideInApp);
        formData.append('Gl_type', isAddedOnGl);
        formData.append('PictureUrl', Image ?? null);

        cs.editCat(token, formData)
            .then((res) => {
                // window.location.reload();
                updateList(res);
            })
            .finally((_) => {
                setLoad(false);
                handleClose();
            });
    };

    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const openDeleteConfirm = () => setDeleteConfirm(true);
    const closeDeleteConfirm = () => setDeleteConfirm(false);
    const deleteConfirmAccept = () => {
        deleteCat();
        closeDeleteConfirm();
    };

    const saveTime = (index, value) => {
        let ur = weekTimes;
        let rm = ur.splice(index, 1, value);
        setWeekTimes([...ur]);
    };

    const onSaveTimeTable = useCallback(() => {
        if (editChildTimeTable) {
            setTimeTableLoad(true);
            let weekArray = [];
            if (weekTimes.length > 0) {
                weekArray = weekTimes.map((item) => {
                    if (!item.enabled && !item.disabled) {
                        return item.values
                            ?.map((i, ind) => {
                                return `${
                                    60 * Number(i.start.substring(0, 2)) +
                                    Number(i.start.substring(3, 5))
                                }-${
                                    60 * Number(i.end.substring(0, 2)) +
                                    Number(i.end.substring(3, 5))
                                }`;
                            })
                            .join(',');
                    } else {
                        if (item.enabled) {
                            return 'Enabled';
                        }
                        if (item.disabled) {
                            return 'Disabled';
                        }
                    }
                });
            }

            const body = {
                CategoryID: ID,
                SubCategoryID: '',
                MonTime: weekArray[0],
                TueTime: weekArray[1],
                WedTime: weekArray[2],
                ThuTime: weekArray[3],
                FriTime: weekArray[4],
                SatTime: weekArray[5],
                SunTime: weekArray[6],
            };
            cs.changeTimeItems(token, body)
                .then((res) => {
                    // console.log(res)
                    if (res) {
                        message.success('Изменено успешно');
                        setEditChildTimeTable(false);
                    } else {
                        message.error('Произошла ошибка');
                    }
                })
                .finally((_) => setTimeTableLoad(false));
        }
    }, [editChildTimeTable, ID, token, weekTimes]);

    const onCancelTimeTable = useCallback(() => {
        setEditChildTimeTable(false);
    }, []);

    const cloneElement = () => {
        if (token && editItem?.ID) {
            setCloneLoad(true);
            cs.cloneItem(token, { id: editItem?.ID, element: 'category' })
                .then((res) => {
                    if (res === 200) {
                        window.location?.reload();
                    } else {
                        message.error('Произошла ошибка');
                    }
                })
                .finally(() => {
                    setCloneLoad(false);
                });
        }
    };
    // const uploadImage = (e) => {
    //         const ff = [...e.target.files].map(item => URL.createObjectURL(item))
    //         setPicture(state => [...state, ...e.target.files])
    // }

    return (
        <Modal className='Modal' open={visible} width={700} onCancel={handleClose}>
            <ConfirmModal
                text={'Удалить категорию'}
                visible={deleteConfirm}
                cancel={deleteConfirmAccept}
                close={closeDeleteConfirm}
            />
            <SelectOrg
                visible={hideOrgModal}
                close={closeHideOrgModal}
                list={orgs}
                selected={orgsList}
                setSelected={setOrgsList}
            />
            <h2 className='Modal__head'>
                {editItem ? 'Редактировать категорию' : 'Добавить категорию'}
            </h2>
            <form className='Modal__form'>
                {/* <Upload
                            showUploadList={false}
                            customRequest={asyncRequest}
                            listType="picture-card"
                            onChange={handleUploadPictureUrl}
                        >
                            <p style={{height: '150px', display: 'flex', alignItems: 'center'}}>Загрузить изображение категории</p>
                        </Upload> */}
                {tempImage && tempImage != 'null' && tempImage != 'undefined' ? (
                    <div className='banners__pic-item'>
                        <img
                            src={tempImage}
                            alt=''
                            // width={PictureUrl.width}
                            // height={PictureUrl.height}
                        />
                        <div className='baners__pic-item--backdrop'>
                            <Upload
                                className='baners__pic-item--new'
                                showUploadList={false}
                                customRequest={asyncRequest}
                                listType='picture-card'
                                onChange={handleUploadPictureUrl}
                            >
                                <p>Загрузить другую картинку</p>
                            </Upload>
                            <Button
                                className='baners__pic-item--delete'
                                type={'button'}
                                variant={'danger'}
                                text='Удалить'
                                styles={{ padding: 8, width: '150px' }}
                                justify={'center'}
                                onClick={() => {
                                    setImage();
                                    setTempImage();
                                    setPictureUrl();
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <Upload
                        showUploadList={false}
                        customRequest={asyncRequest}
                        listType='picture-card'
                        onChange={handleUploadPictureUrl}
                    >
                        <p
                            style={{
                                height: '150px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            Загрузить картинку
                        </p>
                    </Upload>
                )}
                {/* <PlUpload id={'createCatPics'} onChange={(e) => handleUploadPictureUrl(e)} style={{width: '100%', height: 200, flex: '0 0 auto', backgroundColor: '#F8F8F8'}} text={'Загрузить изображение категории'}/> */}
                {window.location.origin === 'https://ibazar.gscore.ru' ||
                window.location.origin === 'https://ident.gscore.ru'
                    ? null
                    : switchCrm(
                          settings,
                          <div className='Modal__form_row'>
                              <Checkbox
                                  shadow={true}
                                  checked={CanOverwriteByIIko == '1'}
                                  onChange={(e) => {
                                      if (e.target.checked) {
                                          setCanOverwriteByIIko('1');
                                      } else {
                                          setCanOverwriteByIIko('0');
                                      }
                                  }}
                                  id={'CanOverwriteByIIko'}
                                  text={`Разрешить iiko перезаписывать категорию`}
                              />
                          </div>,
                          <div className='Modal__form_row'>
                              <Checkbox
                                  shadow={true}
                                  checked={CanOverwriteByIIko == '1'}
                                  onChange={(e) => {
                                      if (e.target.checked) {
                                          setCanOverwriteByIIko('1');
                                      } else {
                                          setCanOverwriteByIIko('0');
                                      }
                                  }}
                                  id={'CanOverwriteByIIko'}
                                  text={`Разрешить RKeeper перезаписывать категорию`}
                              />
                          </div>,
                          <div className='Modal__form_row'>
                              <Checkbox
                                  shadow={true}
                                  checked={CanOverwriteByIIko == '1'}
                                  onChange={(e) => {
                                      if (e.target.checked) {
                                          setCanOverwriteByIIko('1');
                                      } else {
                                          setCanOverwriteByIIko('0');
                                      }
                                  }}
                                  id={'CanOverwriteByIIko'}
                                  text={`Разрешить 1C перезаписывать категорию`}
                              />
                          </div>,
                          <div className='Modal__form_row'>
                              <Checkbox
                                  shadow={true}
                                  checked={CanOverwriteByIIko == '1'}
                                  onChange={(e) => {
                                      if (e.target.checked) {
                                          setCanOverwriteByIIko('1');
                                      } else {
                                          setCanOverwriteByIIko('0');
                                      }
                                  }}
                                  id={'CanOverwriteByIIko'}
                                  text={`Разрешить FrontPad перезаписывать категорию`}
                              />
                          </div>
                      )}

                <div className='Modal__form_row'>
                    {checkIsBao() ? (
                        <Tabs
                            defaultActiveKey='1'
                            items={nameTabs}
                            onChange={() => {}}
                            style={{ width: '100%' }}
                        />
                    ) : (
                        nameTabs[0].children
                    )}
                    {window.location.origin === 'https://uzgen.gscore.ru' ? (
                        <Tabs
                            defaultActiveKey='1'
                            items={nameTabs.filter(
                                (item) => item.key === '1' || item.key === '3'
                            )}
                            onChange={() => {}}
                            style={{ width: '100%' }}
                        />
                    ) : null}
                </div>
                {settingsFront?.freePlateCategory ? (
                    <>
                        <div className='Modal__form_row'>
                            <Input
                                maskType={Number}
                                shadow={true}
                                value={freePlate?.toString()}
                                onChange={(e) => setFreePlate(e.target.value)}
                                placeholder={`Бесплатное блюдо`}
                            />
                        </div>
                        <div className='Modal__form_row'>
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                onChange={handleFreePlateSelect}
                                options={categoryProducts}
                                placeholder={'Бесплатное блюдо'}
                                value={freePlateId}
                            />
                        </div>
                    </>
                ) : null}

                {window.location.origin === 'https://ibazar.gscore.ru' ||
                window.location.origin === 'https://ident.gscore.ru'
                    ? null
                    : switchCrm(
                          settings,
                          <div className='Modal__form_row'>
                              <Input
                                  maskType={String}
                                  shadow={true}
                                  value={IIkoID?.toString()}
                                  onChange={(e) => setIIkoID(e.target.value)}
                                  placeholder={`ID в iIko`}
                              />
                          </div>,
                          <div className='Modal__form_row'>
                              <Input
                                  maskType={String}
                                  shadow={true}
                                  value={IIkoID?.toString()}
                                  onChange={(e) => setIIkoID(e.target.value)}
                                  placeholder={`ID в RKeeper`}
                              />
                          </div>,
                          <div className='Modal__form_row'>
                              <Input
                                  maskType={String}
                                  shadow={true}
                                  value={IIkoID?.toString()}
                                  onChange={(e) => setIIkoID(e.target.value)}
                                  placeholder={`ID в 1C`}
                              />
                          </div>,
                          <div className='Modal__form_row'>
                              <Input
                                  maskType={String}
                                  shadow={true}
                                  value={IIkoID?.toString()}
                                  onChange={(e) => setIIkoID(e.target.value)}
                                  placeholder={`ID в FrontPad`}
                              />
                          </div>
                      )}
                <div className='Modal__form_row'>
                    <Checkbox
                        shadow={true}
                        checked={HideInApp == '1'}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setHideInApp('1');
                            } else {
                                setHideInApp('0');
                            }
                        }}
                        id={'HideInApp'}
                        text={'Скрыть в приложении'}
                    />
                </div>
                {window.location.origin === 'https://ibazar.gscore.ru' ||
                window.location.origin === 'https://ident.gscore.ru' ? null : (
                    <div className='Modal__form_row'>
                        <Checkbox
                            shadow={true}
                            checked={isHideInOrg}
                            onChange={(e) => {
                                setIsHideInOrg(e.target.checked);
                                if (!e.target.checked) {
                                    setOrgsList([]);
                                }
                            }}
                            id={'HiddenInOrganisations'}
                            text={'Скрыть в организациях'}
                        />
                    </div>
                )}

                {settingsFront?.addCategoryHit && (
                    <div className='Modal__form_row'>
                        <Checkbox
                            shadow={true}
                            checked={localIsAddedOnGl}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setAddedOnGl('1');
                                    setLocalIsAddedOnGl(true);
                                } else {
                                    setAddedOnGl('0');
                                    setLocalIsAddedOnGl(false);
                                }
                            }}
                            id={'AddedOnGl'}
                            text={'Добавить на главную страницу'}
                        />
                    </div>
                )}
                {isHideInOrg ? (
                    <>
                        {orgsList?.length == 0 ? (
                            <div className='Modal__form_row'>
                                <Pl
                                    shadow={true}
                                    onClick={openHideOrgModal}
                                    text={'Добавить организацию'}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </div>
                        ) : (
                            <div className='Modal__form_row'>
                                <Pl
                                    style={{ color: 'var(--violet)' }}
                                    styles={{ width: '100%' }}
                                    onClick={openHideOrgModal}
                                    shadow
                                    text={`Выбрано организаций ${orgsList?.length}`}
                                />
                            </div>
                        )}
                    </>
                ) : null}
                {editItem ? (
                    <div className='Modal__form_row'>
                        {editChildTimeTable ? (
                            <Row gutter={[10, 10]}>
                                <Col span={24}>
                                    <TimeSelect
                                        shadow
                                        list={weekTimes}
                                        plate={true}
                                        save={saveTime}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[10, 10]}>
                                        <Col span={12}>
                                            <Button
                                                onClick={onSaveTimeTable}
                                                type={'button'}
                                                text={'Сохранить'}
                                                styles={{ width: '100%' }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Button
                                                onClick={onCancelTimeTable}
                                                type={'button'}
                                                text={'Отмена'}
                                                variant={'danger'}
                                                styles={{ width: '100%' }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ) : (
                            <Button
                                onClick={() => setEditChildTimeTable(true)}
                                text={'Изменить расписание у дочерних элементов'}
                                styles={{ width: '100%' }}
                            />
                        )}
                    </div>
                ) : null}
                {editItem?.ID && (
                    <div className='Modal__form_row'>
                        <EditHr
                            onSave={cs.editParentSubList}
                            type='all'
                            ID={editItem.ID}
                            buttonLabel={'Изменить иерархию у всех дочерних элементов'}
                            shadow={true}
                            isPlate={false}
                        />
                    </div>
                )}

                <div className='Modal__form_action'>
                    {editItem ? (
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <Button
                                    text={'Создать копию категории'}
                                    justify={'flex-start'}
                                    before={<MdContentCopy size={20} color={'#fff'} />}
                                    // disabled
                                    onClick={cloneElement}
                                    load={cloneLoad}
                                />
                            </Col>
                            <Col span={24}>
                                <Button
                                    onClick={editCat}
                                    load={load}
                                    disabled={!Name}
                                    type={'button'}
                                    before={<SaveIcon size={20} color={'#fff'} />}
                                    justify={'flex-start'}
                                    text={'Сохранить'}
                                />
                            </Col>
                            <Col span={24}>
                                <Button
                                    onClick={openDeleteConfirm}
                                    load={delLoad}
                                    type={'button'}
                                    before={<BsTrash size={20} />}
                                    justify={'flex-start'}
                                    text={'Удалить'}
                                    variant={'danger'}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Button
                            onClick={createCat}
                            load={load}
                            disabled={!Name}
                            type={'button'}
                            before={<SaveIcon color={'#fff'} size={20} />}
                            justify={'flex-start'}
                            text={'Создать'}
                        />
                    )}
                </div>
            </form>
        </Modal>
    );
};

export default CreateCategory;
